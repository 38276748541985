import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {derived_properties: {color: { in: "gråa" }}}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				color
				elastic
				form
				waxed
				the_length
			}
			
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "gråa-skosnören"
    }}>{`Gråa Skosnören`}</h1>
    <p>{`Ljusgrå, mörkgrå, mellangrå. Här hittar du skosnören i alla olika nyanser av grå. Har du ett par skor med vita skosnören som du känner att det ska hända något? Byt ut dessa till ett par ljusgråa skosnören så får du lite mer kontrast på dina skor. Eller kanske du har ett par tråkiga skor med tråkiga svarta skosnören? Då har du ett perfekt tillfälle att byta ut dessa mot ett par mörkgråa skosnören. Du behåller fortfarande ungefär samma stil, men ett par mörkgråa skosnören kan ge en helt annan effekt än ett par svarta. Så kika ner alla olika gråa skosnören nedan och se om du hittar några som passar dig. Tänk på att försöka hitta rätt längd, och rätt form, för att de ska passa just dina skor.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      